import "./Table.css";

const Table = (props: any) => {
  return (
    <div className="overflow-auto">
      <table className="table table-hover table-bordered">
        {props.children}
      </table>
    </div>
  );
};

export default Table;
