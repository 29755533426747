import { useCallback, useEffect, useState } from "react";

import { environment } from "../../config";
import useHttp from "../../hook/useHttp";
import { Migration, MigrationFiltersForm } from "../../models/Migrations";
import { formatDateTime } from "../../utils/date-utils";
import Spinner from "../UI/Spinner";
import MigrationsFilter from "./MigrationsFilter";
import MigrationsTable from "./MigrationsTable";
import Paginator from "../UI/Paginator";

const Migrations = () => {
  const { isLoading, error, sendRequest: fetchMigrations } = useHttp();
  // const { sendRequest: deleteMigrations } = useHttp();
  const [migrations, setMigrations] = useState<Migration[]>([]);
  const [filters, setFilters] = useState<MigrationFiltersForm>({});
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const onSearchMigrations = useCallback(() => {
    const params = new URLSearchParams(filters as any);
    fetchMigrations(
      {
        url: `${
          environment.API_ENDPOINT
        }/getMigrations?page=${currentPage}&pageSize=10&${params.toString()}`,
      },
      (response: {
        migrations: Migration[];
        totalPages: number;
        page: number;
      }) => {
        console.log(response);
        const migrations = response.migrations.map((item) => {
          item.event_date = formatDateTime(item.event_date);
          item.createdAt = formatDateTime(item.createdAt);
          item.updatedAt = formatDateTime(item.updatedAt);
          return item;
        });
        setMigrations(migrations);
        setTotalPages(response.totalPages);
      }
    );
  }, [fetchMigrations, currentPage, filters]);

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    onSearchMigrations();
  }, [onSearchMigrations]);

  // const onDeleteMigrations = () => {
  //   deleteMigrations(
  //     { url: `${environment.API_ENDPOINT}/deleteMigrations` },
  //     (response: { migrations: Migration[] }) => {
  //       console.log(response);
  //     }
  //   );
  // };
  const onSubmitFilters = (filtersForm: MigrationFiltersForm) => {
    Object.keys(filtersForm).forEach((key) => {
      const item = (filtersForm as any)[key];
      if (!item) {
        delete (filtersForm as any)[key];
      }
    });
    setFilters(filtersForm);
    onSearchMigrations();
  };
  return (
    <div className="content">
      {/* <button className="btn btn-primary mb-4" onClick={onDeleteMigrations}>
        Cancella migrazioni
      </button> */}
      <MigrationsFilter submitFilters={onSubmitFilters}></MigrationsFilter>
      <div>
        {error && "Si è verificato un problema."}
        {!error && (
          <Spinner showSpinner={isLoading}>
            <MigrationsTable migrationList={migrations}></MigrationsTable>
            <Paginator
              totalPages={totalPages}
              currentPage={currentPage}
              onPageChange={onPageChange}
            ></Paginator>
          </Spinner>
        )}
      </div>
    </div>
  );
};
export default Migrations;
