import { Fragment } from "react";

import SettingsCron from "../components/Settings/SettingsCron";

const SettingsCronPage = () => {
  return (
    <Fragment>
      <h1>Cron</h1>
      <SettingsCron></SettingsCron>
    </Fragment>
  );
};

export default SettingsCronPage;
