import { Log } from "../../models/CronLogs";
import Table from "../UI/Table";

const CronLogDetailTable = (props: { logs: Log[] }) => {
  return (
    <Table>
      <thead>
        <tr className="sticky-header-row">
          <th>Tipo</th>
          <th>Messaggio</th>
        </tr>
      </thead>
      <tbody>
        {props.logs.map((log, index) => (
          <tr key={index}>
            <td>{log.type}</td>
            <td>{log.message}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default CronLogDetailTable;
