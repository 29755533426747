import { Fragment } from "react";
import { Link, useParams } from "react-router-dom";

import CronLogDetail from "../components/CronLogDetail/CronLogDetail";

const CronLogDetailPage = () => {
  const { idCron: idCronParam } = useParams();
  const idCron = idCronParam ? +idCronParam : null;
  console.log(idCronParam);
  return (
    <Fragment>
      <h1 className="mb-5">
        <Link to={'/cron-log'}>
          <button className="btn me-4">
            <i className="bi-arrow-left"></i>
          </button>
        </Link>
        Dettaglio Cron: {idCron}
      </h1>

      {idCron && <CronLogDetail idCron={idCron}></CronLogDetail>}
      {!idCron && <p>Si è verificato un problema.</p>}
    </Fragment>
  );
};

export default CronLogDetailPage;
