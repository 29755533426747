import { useContext } from "react";
import AuthContext from "../../store/auth-context";
import classes from "./Header.module.css";

const Header = (props?: { children?: any }) => {
  const authCtx = useContext(AuthContext);
  return (
    <header className={classes.Header}>
      <div className={classes.HeaderUser}>{authCtx.user.username}</div>
      <button className="btn btn-primary" onClick={authCtx.logout}>Logout</button>
    </header>
  );
};

export default Header;
