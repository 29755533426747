import { ChangeEvent, useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";

import {
  BdsEvent,
  BookingKitEvent,
  SettingEvent,
} from "../../models/Settings";
import Table from "../UI/Table";

const SettingEventsTable = (props: {
  settingEvents: SettingEvent[];
  bdsEvents: BdsEvent[];
  bookingKitEvents: BookingKitEvent[];
  onSaveSettingEvents: (settingEvents: SettingEvent[]) => void;
}) => {
  const { register, control, handleSubmit, reset, getValues, setValue } =
    useForm({
      defaultValues: {
        events: props.settingEvents,
      },
    });
  const { fields } = useFieldArray({
    control,
    name: "events",
  });

  useEffect(() => {
    reset({ events: props.settingEvents });
  }, [reset, props.settingEvents]);
  const handleProdottoChange = (
    index: number,
    event: ChangeEvent<HTMLSelectElement>
  ) => {
    const newValue = +event.target?.value;
    setValue(`events.${index}.idProdottoBds`, newValue);
    reset(getValues());
  };
  const handlePartenzaChange = (
    index: number,
    event: ChangeEvent<HTMLSelectElement>
  ) => {
    const newValue = +event.target?.value;
    setValue(`events.${index}.idPartenzaBds`, newValue);

    reset(getValues());
  };
  return (
    <form
      onSubmit={handleSubmit((data) =>
        props.onSaveSettingEvents(
          data.events.map((event) => {
            event.idPartenzaBds = +event.idPartenzaBds;
            event.idProdottoBds = +event.idProdottoBds;
            return event;
          })
        )
      )}
    >
      <div className="mb-4 ">
        <button title="Salva" className="btn btn-primary" type="submit">
          <i className="bi-save me-3"></i>
          Salva
        </button>
      </div>
      <Table>
        <thead>
          <tr>
            <th>Prodotto Booking Kit</th>
            <th>Prodotto Bds</th>
            <th>Partenza Bds</th>
          </tr>
        </thead>
        <tbody>
          {fields.map((field, index) => {
            const prodotto = props.bdsEvents.find(
              (event) => +event.id === +field.idProdottoBds
            );
            const bookingKitEvent = props.bookingKitEvents.find(
              (event) => event.id === field.idEventBookingKit
            );
            return (
              <tr key={index}>
                <td>{bookingKitEvent?.name}</td>
                <td>
                  <select
                    className="form-select"
                    {...register(`events.${index}.idProdottoBds`)}
                    onChange={(e) => handleProdottoChange(index, e)}
                    required
                  >
                    <option value=""></option>
                    {props.bdsEvents.map((event) => (
                      <option key={event.id} value={event.id}>
                        {event.name}
                      </option>
                    ))}
                  </select>
                </td>
                <td>
                  <select
                    className="form-select"
                    {...register(`events.${index}.idPartenzaBds`)}
                    onChange={(e) => handlePartenzaChange(index, e)}
                    required
                  >
                    <option value=""></option>
                    {props.bdsEvents
                      .find((event) => event.id === (prodotto?.id ?? -1))
                      ?.partenze.map((event) => {
                        return (
                          <option key={event.id} value={event.id}>
                            {event.name}
                          </option>
                        );
                      })}
                  </select>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </form>
  );
};

export default SettingEventsTable;
