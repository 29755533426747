import { useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { environment } from "../../config";
import useHttp from "../../hook/useHttp";
import { User } from "../../models/User";

import AuthContext from "../../store/auth-context";
import Spinner from "../UI/Spinner";
import classes from "./AuthForm.module.css";

const AuthForm = () => {
  const navigate = useNavigate();
  const usernameInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);

  const authCtx = useContext(AuthContext);

  const { isLoading, error, sendRequest: login } = useHttp();

  const submitHandler = async (event: any) => {
    event.preventDefault();

    const enteredUsername = usernameInputRef?.current?.value;
    const enteredPassword = passwordInputRef?.current?.value;
    login(
      {
        url: `${environment.API_ENDPOINT}/auth/login`,
        body: {
          username: enteredUsername,
          password: enteredPassword,
          returnSecureToken: true,
        },
        method: "POST",
      },
      (response: { token: string; user: User }) => {
        authCtx.login(response.token, response.user);
        navigate("/migrations");
      }
    );
  };

  return (
    <section className={classes["auth-container"]}>
      <Spinner showSpinner={isLoading}>
        <div className={classes["auth-box"]}>
          <img
            className={classes["auth-logo"]}
            src="/logo-color.png"
            alt="Logo"
          ></img>
          <h1 className="mb-4">Login</h1>
          <form onSubmit={submitHandler}>
            <div className="input-group">
              <input
                className="form-control"
                type="text"
                id="username"
                required
                ref={usernameInputRef}
                placeholder="Username"
              />
            </div>
            <div className="input-group">
              <input
                className="form-control"
                type="password"
                id="password"
                required
                ref={passwordInputRef}
                placeholder="Password"
              />
            </div>
            <div className={classes.actions}>
              {!isLoading && (
                <button className="btn btn-primary w-100">Login</button>
              )}
              {isLoading && <p>Sending request...</p>}
              {error && <h3 className="mt-5 text-danger">{error}</h3>}
            </div>
          </form>
        </div>
      </Spinner>
    </section>
  );
};

export default AuthForm;
