import { useCallback, useEffect, useState } from "react";

import { environment } from "../../config";
import useHttp from "../../hook/useHttp";
import { CronLog } from "../../models/CronLogs";
import Paginator from "../UI/Paginator";
import Spinner from "../UI/Spinner";
import classes from "./CronLogs.module.css";
import CronLogsFilter from "./CronLogsFilter";
import CronLogsTable from "./CronLogsTable";

const CronLogs = () => {
  const { isLoading, error, sendRequest: fetchCronLogs } = useHttp();
  const {
    isLoading: isLoadingNewCron,
    sendRequest: createNewCron,
  } = useHttp();
  const [cronLogs, setCronLogs] = useState<CronLog[]>([]);
  const [nextSchedule, setNextSchedule] = useState<string>("");
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [statusFilter, setStatoFilter] = useState<string>("");

  const onSearchCronLogs = useCallback(
    (filters?: any) => {
      fetchCronLogs(
        {
          url: `${environment.API_ENDPOINT}/getCronLogs?page=${currentPage}&pageSize=10&status=${statusFilter}`,
        },
        (response: {
          crons: CronLog[];
          totalPages: number;
          page: number;
          nextSchedule: string;
        }) => {
          console.log(response);

          setNextSchedule(response.nextSchedule);
          setCronLogs(response.crons);
          setTotalPages(response.totalPages);
        }
      );
    },
    [fetchCronLogs, currentPage, statusFilter]
  );

  useEffect(() => {
    onSearchCronLogs();
  }, [onSearchCronLogs]);

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };
  const onSubmitFilters = (status: string | null) => {
    console.log(status);
    setStatoFilter(status ?? "");
    setCurrentPage(1);
    onSearchCronLogs();
  };

  const runCron = () => {
    createNewCron(
      {
        url: `${environment.API_ENDPOINT}/run-cron`,
      },
      () => {
        setCurrentPage(1);
        onSearchCronLogs();
      }
    );
  };
  return (
    <div className="content">
      <CronLogsFilter
        submitFilters={onSubmitFilters}
        runCron={runCron}
        nextSchedule={nextSchedule}
      ></CronLogsFilter>
      <div className={classes.CronLogsTable}>
        {error && "Si è verificato un problema."}
        {!error && (
          <Spinner showSpinner={isLoading || isLoadingNewCron}>
            <CronLogsTable logList={cronLogs}></CronLogsTable>
            <Paginator
              totalPages={totalPages}
              currentPage={currentPage}
              onPageChange={onPageChange}
            ></Paginator>
          </Spinner>
        )}
      </div>
    </div>
  );
};
export default CronLogs;
