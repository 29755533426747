import "./Spinner.css";

/*
<div className="position-relative">
  <div *ngIf="showSpinner" className="spinner">
    <ion-spinner name="crescent"></ion-spinner>
  </div>
  <div
    [ngClass]="{ overlay: showSpinner }"
    [style.opacity]="showSpinner ? opacity : 1"
  >
    <ng-content></ng-content>
  </div>
</div>

*/
const Spinner = (props: { children: any; showSpinner: boolean }) => {
  return (
    <div className="SpinnerContainer">
      {props.showSpinner && (
        <div className="Spinner">
          <div className="sk-chase">
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
          </div>
        </div>
      )}

      <div className={props.showSpinner ? "SpinnerOverlay" : ""}>
        {props.children}
      </div>
    </div>
  );
};
export default Spinner;
