import { Fragment } from "react";

import SettingsEventRegiondo from "../components/Settings/SettingsEventRegiondo";

const SettingsEventRegiondoPage = () => {
  return (
    <Fragment>
      <h1>Prodotti regiondo</h1>
      <SettingsEventRegiondo></SettingsEventRegiondo>
    </Fragment>
  );
};

export default SettingsEventRegiondoPage;
