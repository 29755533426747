import "./Paginator.css";

const Paginator = (props: {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}) => {
  return (
    <nav aria-label="Paginator">
      <ul className="pagination">
        {Array.from(Array(props.totalPages)).map((x, i) => {
          let itemClasses = "page-item ";
          const page = i + 1;
          if (page === props.currentPage) {
            itemClasses += "active";
          }
          return (
            <li
              className={itemClasses}
              key={i}
              onClick={() => props.onPageChange(page)}
            >
              <span className="page-link">{page}</span>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Paginator;
