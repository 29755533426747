import { FormEvent, useRef } from "react";
import { formatDateTime } from "../../utils/date-utils";

import classes from "./CronLogsFilter.module.css";

const CronLogsFilter = (props: {
  nextSchedule: string;
  submitFilters: (status: string | null) => void;
  runCron: () => void;
}) => {
  const statusInputRef = useRef<HTMLSelectElement>(null);

  const submitFilters = (event: FormEvent) => {
    event.preventDefault();
    const status = statusInputRef?.current?.value ?? null;
    props.submitFilters(status);
  };
  return (
    <form className={classes.CronFilters} onSubmit={submitFilters}>
      <div className={classes.CronFiltersLeft}>
        <div className="row">
          <div className="col-lg-2">
            <div className="input-group">
              <select
                ref={statusInputRef}
                id="status"
                className="form-select"
                placeholder="Stato"
              >
                <option value="">Stato</option>
                <option value="COMPLETED">COMPLETED</option>
                <option value="STARTED">STARTED</option>
                <option value="FAILED">FAILED</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.CronFiltersRight}>
        <span>
          Prossima schedulazione: <strong>{formatDateTime(props.nextSchedule)}</strong>
        </span>
        <button
          type="button"
          onClick={props.runCron}
          className="btn btn-secondary me-5"
        >
          <i className="bi-play-btn me-3"></i>
          Esegui nuovo cron
        </button>
        <button className="btn btn-primary">
          <i className="bi-search"></i>
        </button>
      </div>
    </form>
  );
};

export default CronLogsFilter;
