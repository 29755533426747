import { Fragment } from "react";

import Migrations from "../components/Migrations/Migrations";

const MigrationListPage = () => {
  return (
    <Fragment>
      <h1>Elenco Migrazioni</h1>
      <Migrations></Migrations>
    </Fragment>
  );
};

export default MigrationListPage;
