import { NavLink } from "react-router-dom";
import classes from "./SideMenu.module.css";
import SideMenuHeader from "./SideMenuHeader";

const SideMenu = () => {
  const menu = [
    {
      name: "Migrazioni",
      path: "/migrations",
      icon: "arrow-left-right",
    },
    {
      name: "Cron",
      path: "/cron-log",
      icon: "clock",
    },
    {
      name: "Impostazioni",
      path: "/settings",
      icon: "gear",
    },
  ];
  return (
    <div className={classes.SideMenu}>
      <SideMenuHeader></SideMenuHeader>
      <ul className={classes.Menu}>
        {menu.map((item, index) => (
          <li key={index}>
            <NavLink
              to={item.path}
              className={({ isActive }) =>
                isActive ? classes.MenuActive : undefined
              }
            >
              <i className={classes.Icon + " bi-" + item.icon}></i>
              {item.name}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SideMenu;
