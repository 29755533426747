import { Migration } from "../../models/Migrations";
import Table from "../UI/Table";

const MigrationsTable = (props: { migrationList: Migration[] }) => {
  return (
    <Table>
      <thead>
        <tr>
          <th>Id</th>
          <th>Fonte</th>
          <th>Id Originale</th>
          <th>Bds Id</th>
          <th>Stato</th>
          <th>Data Evento</th>
          <th>Nome Cliente</th>
          <th>Cognome Cliente</th>
          <th>Email Cliente</th>
          <th>Note</th>
          <th>Creato il </th>
          <th>Aggiornato il </th>
        </tr>
      </thead>
      <tbody>
        {props.migrationList.map((item) => (
          <tr key={item.id}>
            <td>{item.id}</td>
            <td>{item.source}</td>
            <td>
              <a
                href={
                  item.source === "REGIONDO"
                    ? "https://www.regiondo.com/resellers/account/bookingsview/key/" +
                      item.original_id
                    : "https://eu5.bookingkit.de/order/index/" +
                      item.original_id
                }
                target="_blank"
                rel="noreferrer"
              >
                {item.original_id}
              </a>
            </td>
            <td>
              <a
                href={
                  "http://bds.mediacy.it/#/sistema/prenota/dettaglio/" +
                  item.bds_id
                }
                target="_blank"
                rel="noreferrer"
              >
                {item.bds_id}
              </a>
            </td>
            <td>{item.status}</td>
            <td>{item.event_date.toString()}</td>
            <td>{item.client_name}</td>
            <td>{item.client_surname}</td>
            <td>{item.client_email}</td>
            <td>{item.note}</td>
            <td>{item.createdAt.toString()}</td>
            <td>{item.updatedAt.toString()}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default MigrationsTable;
