import { useEffect, useState } from "react";

import { environment } from "../../config";
import useHttp from "../../hook/useHttp";
import { CronLog } from "../../models/CronLogs";
import { formatDateTime } from "../../utils/date-utils";
import CronLogDetailTable from "./CronLogDetailTable";
import { Editor } from "@tinymce/tinymce-react";
import Spinner from "../UI/Spinner";

const CronLogDetail = (props: { idCron: number }) => {
  const { isLoading, sendRequest: fetchCronLogDetail } = useHttp();
  const [cronDetail, setCronLogDetail] = useState<CronLog>({
    logs: [],
  } as unknown as CronLog);

  useEffect(() => {
    fetchCronLogDetail(
      { url: `${environment.API_ENDPOINT}/getCronLogs/${props.idCron}` },
      (response: { log: CronLog }) => {
        console.log(response);
        setCronLogDetail(response.log);
      }
    );
  }, [fetchCronLogDetail, props.idCron]);

  return (
    <Spinner showSpinner={isLoading}>
      <div className="content">
        <div className="row mb-4">
          <div className="col-auto">
            <p>Stato: {cronDetail.status}</p>
          </div>
          <div className="col-auto">
            <p>Cron iniziato il: {formatDateTime(cronDetail.createdAt)}</p>
          </div>
          {cronDetail.ended && (
            <div className="col-auto">
              <p>Cron terminato il: {formatDateTime(cronDetail.ended)}</p>
            </div>
          )}
        </div>
        <h2>Log testuali</h2>
        <Editor disabled={true} initialValue={cronDetail.logs_text} />
        <h2 className="mt-4 mb-4">Elenco log</h2>
        <CronLogDetailTable logs={cronDetail.logs} />
      </div>
    </Spinner>
  );
};
export default CronLogDetail;
