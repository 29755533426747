import { useEffect, useState } from "react";

import { environment } from "../../config";
import useHttp from "../../hook/useHttp";
import {
  BdsEvent,
  RegiondoEvent,
  SettingEventRegiondo,
  SettingEventsRegiondo,
} from "../../models/Settings";
import Spinner from "../UI/Spinner";
import SettingEventsRegiondoTable from "./SettingsEventTableRegiondo";

const SettingsEventRegiondo = () => {
  const { isLoading, error, sendRequest: fetchSettingEvents } = useHttp();
  const {
    isLoading: isLoadingSave,
    error: errorSave,
    sendRequest: saveSettingEvents,
  } = useHttp();
  const [settingEvents, setSettingEvents] = useState<SettingEventRegiondo[]>(
    []
  );
  const [bdsEvents, setBdsEvents] = useState<BdsEvent[]>([]);
  const [regiondoEvents, setBookingKitEvents] = useState<RegiondoEvent[]>([]);
  const onSaveSettingEvents = (settingEventsForm: SettingEventRegiondo[]) => {
    saveSettingEvents(
      {
        url: `${environment.API_ENDPOINT}/setting/eventsRegiondo`,
        body: {
          settingEvents: settingEventsForm,
        },
        method: "PUT",
      },
      (response: { settingsEvent: SettingEventRegiondo[] }) => {
        setSettingEvents(response.settingsEvent);
      }
    );
  };

  useEffect(() => {
    fetchSettingEvents(
      { url: `${environment.API_ENDPOINT}/setting/eventsRegiondo` },
      (response: SettingEventsRegiondo) => {
        console.log(response);
        const events = response.regiondoEvents.map((event) => {
          const setting = response.settingsEvent.find(
            (item) => item.idRegiondo === event.product_id
          );
          if (setting) {
            return {
              ...setting,
              idRegiondo: event.product_id,
            };
          }
          return {
            idPartenzaBds: null,
            idProdottoBds: null,
            idRegiondo: event.product_id,
          };
        }) as SettingEventRegiondo[];
        setSettingEvents(events);
        setBdsEvents(response.bdsEvents);
        setBookingKitEvents(response.regiondoEvents);
      }
    );
  }, [fetchSettingEvents]);

  return (
    <div className="py-5">
      <div>
        {(error || errorSave) && "Si è verificato un problema."}
        {!error && !errorSave && (
          <Spinner showSpinner={isLoading || isLoadingSave}>
            <SettingEventsRegiondoTable
              bdsEvents={bdsEvents}
              regiondoEvents={regiondoEvents}
              settingEvents={settingEvents}
              onSaveSettingEvents={onSaveSettingEvents}
            ></SettingEventsRegiondoTable>
          </Spinner>
        )}
      </div>
    </div>
  );
};
export default SettingsEventRegiondo;
