import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import AuthContext from "../store/auth-context";

export const PrivateOutlet = () => {
  const authCtx = useContext(AuthContext);
  console.log(authCtx.isLoggedIn);
  return authCtx.isLoggedIn ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : (
    <Navigate to="/auth" />
  );
};
