import { useEffect, useRef, useState } from "react";

import { environment } from "../../config";
import useHttp from "../../hook/useHttp";
import Spinner from "../UI/Spinner";

const SettingsCron = () => {
  const { isLoading, error, sendRequest: fetchSettingsCron } = useHttp();
  const {
    isLoading: isLoadingSave,
    error: errorSave,
    sendRequest: saveSettingsCron,
  } = useHttp();
  const [settingsCron, setSettingsCron] = useState<string>();

  const scheduleInputRef = useRef<HTMLSelectElement>(null);

  const onSaveSettingsCron = () => {
    const schedule = scheduleInputRef?.current?.value;
    saveSettingsCron(
      {
        url: `${environment.API_ENDPOINT}/setting/cron`,
        body: {
          schedule,
        },
        method: "PUT",
      },
      (response: { settingsCron: { schedule: string } }) => {
        setSettingsCron(response.settingsCron.schedule);
      }
    );
  };
  useEffect(() => {
    fetchSettingsCron(
      { url: `${environment.API_ENDPOINT}/setting/cron` },
      (response: any) => {
        setSettingsCron(response.settingsCron.schedule);
        console.log(response);
      }
    );
  }, [fetchSettingsCron]);

  return (
    <div className="py-5">
      <div>
        {(error || errorSave) && "Si è verificato un problema."}
        {!error && !errorSave && (
          <Spinner showSpinner={isLoading || isLoadingSave}>
            <label>Schedulazione</label>
            <select ref={scheduleInputRef} className="form-select w-auto">
              <option selected={settingsCron === "* * * * *"} value="* * * * *">
                Ogni minuto
              </option>
              <option
                selected={settingsCron === "*/3 * * * *"}
                value="*/3 * * * *"
              >
                Ogni 3 minuti
              </option>
              <option
                selected={settingsCron === "0 */3 * * *"}
                value="0 */3 * * *"
              >
                Ogni 3 ore
              </option>
              <option
                selected={settingsCron === "0 */1 * * *"}
                value="0 */1 * * *"
              >
                Ogni ora
              </option>
              <option selected={settingsCron === "0 8 * * *"} value="0 8 * * *">
                Ogni giorno alle 8
              </option>
            </select>
            <button
              className="mt-4 btn btn-primary"
              onClick={onSaveSettingsCron}
            >
              <i className="bi-save me-3"></i>
              Salva
            </button>
          </Spinner>
        )}
      </div>
    </div>
  );
};
export default SettingsCron;
