import { Fragment } from "react";

import SettingsEvent from "../components/Settings/SettingsEvent";

const SettingsEventPage = () => {
  return (
    <Fragment>
      <h1>Prodotti Booking Kit</h1>
      <SettingsEvent></SettingsEvent>
    </Fragment>
  );
};

export default SettingsEventPage;
