import { Fragment } from "react";
import { NavLink, Outlet } from "react-router-dom";
import classes from "./SettingsLayout.module.css";

const SettingsLayout = () => {
  return (
    <Fragment>
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <NavLink className="nav-link" to="/settings/cron">
            Cron
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className="nav-link"
            aria-current="page"
            to="/settings/eventsBookingKit"
          >
            Prodotti Booking Kit
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className="nav-link"
            aria-current="page"
            to="/settings/eventsRegiondo"
          >
            Prodotti Regiondo
          </NavLink>
        </li>
      </ul>
      <div className={classes.NavBar + " content"}>
        <Outlet></Outlet>
      </div>
    </Fragment>
  );
};

export default SettingsLayout;
