import { useState, useCallback, useContext } from "react";
import AuthContext from "../store/auth-context";

const useHttp = () => {
  const authCtx = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const sendRequest = useCallback(
    async (
      requestConfig: {
        url: string;
        headers?: Headers;
        body?: any;
        method?: string;
      },
      applyData?: any
    ) => {
      setIsLoading(true);
      setError(null);
      try {
        let headers: any = requestConfig.headers
          ? { ...requestConfig.headers, "Content-Type": "application/json" }
          : { "Content-Type": "application/json" };
        if (authCtx.token) {
          headers = { ...headers, Authorization: authCtx.token };
        }
        const response = await fetch(requestConfig.url ?? "", {
          method: requestConfig.method ? requestConfig.method : "GET",
          headers,
          body: requestConfig.body ? JSON.stringify(requestConfig.body) : null,
        });

        if (!response.ok) {
          throw new Error(await response.json());
        }

        const data = await response.json();
        applyData(data);
      } catch (err: any) {
        setError(err.message || "Something went wrong!");
      }
      setIsLoading(false);
    },
    [authCtx]
  );

  return {
    isLoading,
    error,
    sendRequest,
  };
};

export default useHttp;
