import { FormEvent, useRef } from "react";

import { MigrationFiltersForm } from "../../models/Migrations";

const MigrationsFilter = (props: {
  submitFilters: (filters: MigrationFiltersForm) => void;
}) => {
  const bookingKitIdInputRef = useRef<HTMLInputElement>(null);
  const nomeClienteInputRef = useRef<HTMLInputElement>(null);
  const cognomeClienteInputRef = useRef<HTMLInputElement>(null);
  const emailClienteInputRef = useRef<HTMLInputElement>(null);

  const submitFilters = (event: FormEvent) => {
    event.preventDefault();
    const filters: MigrationFiltersForm = {
      original_id: bookingKitIdInputRef?.current?.value,
      client_name: nomeClienteInputRef?.current?.value,
      client_surname: cognomeClienteInputRef?.current?.value,
      client_email: emailClienteInputRef?.current?.value,
    };
    props.submitFilters(filters);
  };
  return (
    <form className="row" onSubmit={submitFilters}>
      <div className="col-lg-2">
        <div className="input-group">
          <input
            ref={bookingKitIdInputRef}
            placeholder="Booking Kit ID"
            className="form-control"
            type="text"
            id="bookingKitId"
          />
        </div>
      </div>
      <div className="col-lg-2">
        <div className="input-group">
          <input
            ref={nomeClienteInputRef}
            placeholder="Nome Cliente"
            className="form-control"
            type="text"
            id="nomeCliente"
          />
        </div>
      </div>
      <div className="col-lg-2">
        <div className="input-group">
          <input
            ref={cognomeClienteInputRef}
            placeholder="Cognome Cliente"
            className="form-control"
            type="text"
            id="cognomeCliente"
          />
        </div>
      </div>
      <div className="col-lg-2">
        <div className="input-group">
          <input
            ref={emailClienteInputRef}
            placeholder="Email Cliente"
            className="form-control"
            type="text"
            id="emailCliente"
          />
        </div>
      </div>
      <div className="col-lg-2">
        <button className="btn btn-primary">
          <i className="bi-search"></i>
        </button>
      </div>
    </form>
  );
};

export default MigrationsFilter;
