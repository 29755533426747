import "./App.css";

import { useContext } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import { PrivateOutlet } from "./hoc/PrivateRoute";
import AuthPage from "./pages/AuthPage";
import MigrationListPage from "./pages/MigrationListPage";
import AuthContext from "./store/auth-context";
import CronLogPage from "./pages/CronLogPage";
import CronLogDetailPage from "./pages/CronLogDetailPage";
import SettingsLayout from "./components/Layout/SettingsLayout";
import SettingsEventPage from "./pages/SettingsEventPage";
import SettingsCronPage from "./pages/SettingsCronPage";
import SettingsEventRegiondoPage from "./pages/SettingsEventRegiondoPage";

function App() {
  const authCtx = useContext(AuthContext);
  console.log(authCtx.isLoggedIn);
  return (
    <Routes>
      <Route path="/auth" element={<AuthPage />}></Route>

      <Route path="/" element={<PrivateOutlet />}>
        <Route path="/" element={<Navigate to="/migrations" />} />
        <Route path="/migrations" element={<MigrationListPage />} />
        <Route path="/cron-log" element={<Outlet />}>
          <Route path="" element={<CronLogPage />} />
          <Route path=":idCron" element={<CronLogDetailPage />} />
        </Route>
        <Route path="/settings" element={<SettingsLayout />}>
          <Route path="" element={<Navigate to="/settings/cron" />} />
          <Route path="*" element={<Navigate to="/settings/cron" />} />
          <Route path="/settings/eventsBookingKit" element={<SettingsEventPage />} />
          <Route path="/settings/eventsRegiondo" element={<SettingsEventRegiondoPage />} />
          <Route path="/settings/cron" element={<SettingsCronPage />} />
        </Route>
      </Route>
      <Route path="*" element={<Navigate to="/migrations" />} />
    </Routes>
  );
}

export default App;
