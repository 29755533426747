import { Fragment } from "react";
import CronLogs from "../components/CronLogs/CronLogs";

const CronLogPage = () => {
  return (
    <Fragment>
      <h1>Elenco Cron</h1>
      <CronLogs></CronLogs> 
    </Fragment>
  );
};

export default CronLogPage;
