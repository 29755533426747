import { Link } from "react-router-dom";
import { CronLog } from "../../models/CronLogs";
import { formatDateTime } from "../../utils/date-utils";
import Table from "../UI/Table";

const CronLogsTable = (props: { logList: CronLog[] }) => {
  return (
    <Table>
      <thead>
        <tr>
          <th>ID</th>
          <th>Stato</th>
          <th>Fonte</th>
          <th>Data inizio</th>
          <th>Data fine</th>
          <th>Prenotazioni create</th>
          <th>Prenotazioni create fallite</th>
          <th>Prenotazioni modificate</th>
          <th>Prenotazioni modificate fallite</th>
          <th>Prenotazioni cancellate</th>
          <th>Dettaglio</th>
        </tr>
      </thead>
      <tbody>
        {props.logList.map((item) => (
          <tr key={item.id}>
            <td>{item.id}</td>
            <td>{item.status}</td>
            <td>{item.source}</td>
            <td>{formatDateTime(item.started)}</td>
            <td>{formatDateTime(item.ended)}</td>
            <td>{item.reservation_created}</td>
            <td>{item.reservation_created_failed}</td>
            <td>{item.reservation_edited}</td>
            <td>{item.reservation_edited_failed}</td>
            <td>{item.reservation_deleted}</td>
            <td>
              <Link to={"/cron-log/" + item.id}>
                <button className="btn btn-primary">
                  <i className="bi-info-circle"></i>
                </button>
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default CronLogsTable;
