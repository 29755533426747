import Header from "./Header";
import classes from "./Layout.module.css";
import SideMenu from "./SideMenu";

const Layout = (props: { children: any }) => {
  return (
    <div className={classes.Layout}>
      <SideMenu></SideMenu>
      <div className={classes.LayoutContent}>
        <Header></Header>
        <main>{props.children}</main>
      </div>
    </div>
  );
};

export default Layout;
